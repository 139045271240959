import React from "react";
import { PageProps } from "gatsby";
import styled from "styled-components";

import SEO from "../components/SEO";
import { H1 } from "../components/Text";
import { PaddingContainer } from "../components/Containers";

const InfoContainer = styled.div`
  max-width: 64ch;
  display: grid;
  gap: 2rem 0;
`;

const DutchPaymentFailedPage: React.FC<PageProps> = ({ location }) => (
  <>
    <SEO title="Betaling mislukt" lang="nl" pathName={location.pathname} />
    <PaddingContainer>
      <H1>Betaling mislukt</H1>
      <InfoContainer>
        <p>
          De betaling is helaas niet gelukt en uw bestelling is niet geplaatst.
        </p>
      </InfoContainer>
    </PaddingContainer>
  </>
);

export default DutchPaymentFailedPage;
